"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormParser = void 0;
const form_builder_1 = require("../form_builder/form_builder");
const json_schema_keys_1 = require("../json_schema_keys");
const const_go_to_flow_dto_1 = require("../models/conditionals/const_go_to_flow_dto");
const conditions_1 = require("../models/dependencies/conditions");
const dependency_parser_1 = require("./dependency_parser");
const field_parser_1 = require("./field_parser");
const const_dependency_dto_1 = require("../models/dependencies/const_dependency_dto");
class FormParser {
    constructor() {
        this.fieldParser = new field_parser_1.FieldParser();
    }
    parse(formObject) {
        const builder = form_builder_1.FormBuilder.newForm();
        const pages = formObject[json_schema_keys_1.JsonSchemaKeys.PAGES_KEY];
        for (const page of pages) {
            this.addPageToBuilder(pages, page, builder, json_schema_keys_1.JsonSchemaKeys.ROOT_SCHEMA_KEY);
        }
        const flows = formObject["flows"];
        if (flows) {
            for (const [flowKey, flow] of Object.entries(flows)) {
                if (!builder.getFlows().includes(flowKey)) {
                    builder.addEmptyFlow(flowKey);
                }
                const pages = flow[json_schema_keys_1.JsonSchemaKeys.PAGES_KEY];
                for (const page of pages) {
                    this.addPageToBuilder(pages, page, builder, flowKey);
                }
            }
        }
        return builder;
    }
    addPageToBuilder(pages, page, builder, flowKey) {
        var _a, _b, _c, _d;
        const definitions = (_a = page.schema.definitions) !== null && _a !== void 0 ? _a : {};
        for (const definitionKey of Object.keys(definitions)) {
            if (builder
                .fieldDefinitions()
                .map((def) => def.uniqueKey())
                .includes(definitionKey)) {
                continue;
            }
            const field = this.fieldParser.parse(definitionKey, false, definitions[definitionKey], this.findUiSchema(definitionKey, page), []);
            builder.addFieldDefinition(field);
        }
        const pageNumber = pages.indexOf(page);
        if (pageNumber !== 0) {
            builder.insertBlankPageAfter(pageNumber - 1, flowKey);
        }
        builder.editPageTitle((_b = page.schema.title) !== null && _b !== void 0 ? _b : "", pageNumber, flowKey);
        if (!page.uiSchema) {
            throw new Error(`uiSchema not found for ${flowKey} page ${pageNumber}`);
        }
        const uiOrder = page.uiSchema[json_schema_keys_1.JsonSchemaKeys.UI_ORDER_KEY];
        if (!uiOrder) {
            throw new Error(`uiOrder not found for ${flowKey} page ${pageNumber}`);
        }
        const fieldsOfPage = this.parseFieldsOfPage(uiOrder, page, flowKey, pageNumber, builder.fieldDefinitions());
        builder.addFieldsToPage(fieldsOfPage, pageNumber, flowKey);
        builder.setOrderOfPage(fieldsOfPage, pageNumber, flowKey);
        const dependencyParser = new dependency_parser_1.DependencyParser(fieldsOfPage);
        const dtos = dependencyParser.parse(page.schema.dependencies);
        for (const dto of dtos) {
            if (dto instanceof const_dependency_dto_1.ConstDependencyDTO) {
                builder.addConstDependencyToField(dto);
            }
            else {
                builder.addReqDependencyToField(dto);
            }
        }
        if ((_c = page.conditionals) === null || _c === void 0 ? void 0 : _c.onChange) {
            for (const [uniqueKey, ifThenList] of Object.entries(page.conditionals.onChange)) {
                for (const ifThen of ifThenList) {
                    this.addGoToFlowCondition(ifThen, uniqueKey, fieldsOfPage, builder, conditions_1.ConditionalType.onChange);
                }
            }
        }
        if ((_d = page.conditionals) === null || _d === void 0 ? void 0 : _d.onSubmit) {
            for (const ifThen of page.conditionals.onSubmit) {
                const uniqueKey = ifThen.if[0].required[0];
                this.addGoToFlowCondition(ifThen, uniqueKey, fieldsOfPage, builder, conditions_1.ConditionalType.onSubmit);
            }
        }
    }
    addGoToFlowCondition(ifThen, uniqueKey, fieldsOfPage, builder, type) {
        if (ifThen.if.length !== 1) {
            throw new Error("if-lists must contain exactly one element");
        }
        if (ifThen.then.length !== 1) {
            throw new Error("then-lists must contain exactly one element");
        }
        const isOfTypeGoToFlow = ifThen.then[0].type === json_schema_keys_1.JsonSchemaKeys.ACTION_TYPE_GO_TO_FLOW;
        if (!isOfTypeGoToFlow) {
            throw new Error(`The only then-condition action type currently supported is ${json_schema_keys_1.JsonSchemaKeys.ACTION_TYPE_GO_TO_FLOW}`);
        }
        const requiredValue = ifThen.if[0].properties[uniqueKey].const;
        const destinationFlow = ifThen.then[0].args.name;
        const parentField = fieldsOfPage.find((f) => f.uniqueKey() === uniqueKey);
        const dto = new const_go_to_flow_dto_1.ConstGoToFlowDTO(parentField, requiredValue, destinationFlow, type);
        builder.addConstGoToFlowCondition(dto);
    }
    parseFieldsOfPage(uiOrder, page, flowKey, pageNumber, definitions) {
        return uiOrder.map((uniqueKey) => {
            var _a;
            const props = this.findProperties(page, uniqueKey);
            if (props === null || props === undefined) {
                throw new Error(`Could not find properties of ${uniqueKey}`);
            }
            const uiSchema = (_a = page.uiSchema[uniqueKey]) !== null && _a !== void 0 ? _a : {};
            if (!page.schema.required) {
                throw new Error(`Required field list not found for ${flowKey} page ${pageNumber}`);
            }
            const isRequired = page.schema.required.includes(uniqueKey);
            return this.fieldParser.parse(uniqueKey, isRequired, props, uiSchema, definitions);
        });
    }
    findProperties(obj, uniqueKey) {
        var result = null;
        for (const key of Object.keys(obj)) {
            if (key === json_schema_keys_1.JsonSchemaKeys.PROPERTIES_KEY) {
                const props = obj[key][uniqueKey];
                if (props && !("const" in props) && !result) {
                    result = props;
                }
            }
            if (typeof obj[key] === "object" && obj[key] !== null) {
                const nestedResult = this.findProperties(obj[key], uniqueKey);
                if (nestedResult && !result) {
                    result = nestedResult;
                }
            }
        }
        return result;
    }
    findUiSchema(definitionKey, pageObject) {
        const props = pageObject.schema.properties;
        var fieldKey = null;
        for (const [k, v] of Object.entries(props)) {
            if (json_schema_keys_1.JsonSchemaKeys.REF_KEY in v) {
                const ref = v[json_schema_keys_1.JsonSchemaKeys.REF_KEY];
                const isRefToDefinition = ref.includes(definitionKey);
                if (isRefToDefinition) {
                    fieldKey = k;
                    break;
                }
            }
        }
        if (fieldKey === null) {
            return {};
        }
        else {
            return pageObject.uiSchema[fieldKey];
        }
    }
}
exports.FormParser = FormParser;
